import React, { useState } from "react";
import { useFormik } from "formik";
import { goToTop } from "helpers/helpers";
import Switch from "components/Switch";
import CardOferta from "components/CardOferta";
import TitleOferta from "components/TItleOferta";
import NavigateButtons from "components/NavigateButtons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsiveMultipleItems = {
   superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
   },
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
   },
};

const responsiveSingleItem = {
   superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
   },
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
   },
};

export default function Step4({ currentStep, setCurrentStep, stepsState, persistState, consumer_type, tenant_assoc }) {
   console.log("🚀 ~ Step4 ~ tenant_assoc:", tenant_assoc);
   console.log("🚀 ~ Step4 ~ stepsState:", currentStep);
   const [isSwitchOn, setIsSwitchOn] = useState(consumer_type !== "pf");
   const denominare = isSwitchOn ? "MWh" : "kWh";
   const [showError, setShowError] = useState(false);
   const [errorMessages, setErrorMessages] = useState();

   const [isSelectedEnergy, setIsSelectedEnergy] = useState(stepsState.selectedOffers?.energy ? true : false);
   const [selectedCardEnergy, setSelectedCardEnergy] = useState(stepsState.selectedOffers?.energy ? stepsState.selectedOffers.energy : null);
   const [isSelectedGas, setIsSelectedGas] = useState(stepsState.selectedOffers?.gas ? true : false);
   const [selectedCardGas, setSelectedCardGas] = useState(stepsState.selectedOffers?.gas ? stepsState.selectedOffers.gas : null);

   const formik = useFormik({
      enableReinitialize: true,
      initialValues: stepsState,
      onSubmit: values => {
         if (stepsState.offers?.energy && stepsState.offers?.gas) {
            if (isSelectedEnergy && isSelectedGas) {
               updateAndPersistState(selectedCardEnergy, selectedCardGas);
            } else {
               setShowError(true);
               setErrorMessages(`Vă rugăm să selectați oferta pentru ELECTRICITATE și GAZ.`);
            }
         } else {
            if (isSelectedEnergy || isSelectedGas) {
               updateAndPersistState(isSelectedEnergy ? selectedCardEnergy : null, isSelectedGas ? selectedCardGas : null);
            } else {
               setShowError(true);
               setErrorMessages(`Vă rugăm să selectați oferta.`);
            }
         }
      },
   });

   const updateAndPersistState = (energy, gas) => {
      persistState(prevState => ({
         ...prevState,
         step4: {
            ...prevState.step4,
            selectedOffers: { energy: energy, gas: gas },
         },
      }));

      goToTop();
      setCurrentStep(currentStep + 1);
      sessionStorage.setItem("sessionCurrentStep", currentStep + 1);
   };

   const handleSelectCard = (typeOfOffer, card) => {
      if (typeOfOffer === "ELECTRICITATE") {
         if (isSelectedEnergy && selectedCardEnergy === card.offer_id) {
            setIsSelectedEnergy(false);
            setSelectedCardEnergy(null);
         } else {
            setIsSelectedEnergy(true);
            setSelectedCardEnergy(card.offer_id);
         }
      } else {
         if (isSelectedGas && selectedCardGas === card.offer_id) {
            setIsSelectedGas(false);
            setSelectedCardGas(null);
         } else {
            setIsSelectedGas(true);
            setSelectedCardGas(card.offer_id);
         }
      }
   };

   const handleSwitchClick = () => {
      setIsSwitchOn(!isSwitchOn);
   };

   const isPersoanaJuridica = consumer_type === "pj";
   return (
      <section className="pb-55">
         <div className="container">
            <div className="col-md-10 mx-auto">
               <h2 className="step-title">Oferta Nova Power & Gas</h2>
               <h3 className="step-description col-md-9 mx-auto">
                  Vă prezentăm, mai jos ofertele noastre. Alege opțiunea care ți se potrivește și continuă cu semnare online.
               </h3>
               <form onSubmit={formik.handleSubmit}>
                  <Switch isSwitchOn={isSwitchOn} handleSwitchClick={handleSwitchClick} />
                  <TitleOferta isPersoanaJuridica={isPersoanaJuridica} />

                  {formik.values.offers?.energy ? (
                     <Carousel responsive={formik.values.offers?.energy.length > 1 ? responsiveMultipleItems : responsiveSingleItem}>
                        {formik.values.offers.energy.map((oferta, index) => (
                           <CardOferta
                              key={index}
                              oferta={oferta}
                              denominare={denominare}
                              isSwitchOn={isSwitchOn}
                              error={formik.errors}
                              touched={formik.touched}
                              setFieldValue={formik.setFieldValue}
                              currentStep={currentStep}
                              tenant_assoc={tenant_assoc}
                              isWithSelect={true}
                              typeOfOffer={"ELECTRICITATE"}
                              isPersoanaJuridica={isPersoanaJuridica}
                              onSelect={card => handleSelectCard("ELECTRICITATE", card)}
                              isCardSelected={isSelectedEnergy && selectedCardEnergy === oferta.offer_id}
                           />
                        ))}
                     </Carousel>
                  ) : null}

                  {formik.values.offers?.gas ? (
                     <Carousel
                        key={"gas"}
                        responsive={formik.values.offers?.gas.length > 1 ? responsiveMultipleItems : responsiveSingleItem}
                        itemClass="mr-1"
                        sliderClass={"mt-1"}
                     >
                        {formik.values.offers.gas.map((oferta, index) => (
                           <CardOferta
                              key={index}
                              oferta={oferta}
                              denominare={denominare}
                              error={formik.errors}
                              touched={formik.touched}
                              isSwitchOn={isSwitchOn}
                              setFieldValue={formik.setFieldValue}
                              currentStep={currentStep}
                              isWithSelect={true}
                              typeOfOffer={"GAZ"}
                              isPersoanaJuridica={isPersoanaJuridica}
                              onSelect={card => handleSelectCard("GAZ", card)}
                              isCardSelected={isSelectedGas && selectedCardGas === oferta.offer_id}
                           />
                        ))}
                     </Carousel>
                  ) : null}

                  <div className="row">
                     <div className="info-pachete-container col-lg-6 col-md-10 col-sm-12 m-auto">
                        <p className="info-pachete-title">Toate pachetele includ:</p>
                        <p className="info-pachete-text">
                           <span>Factură electronică</span>
                           <span className={"mx-2"}>Contractare online</span>
                           <span>Acces my account</span>
                        </p>
                     </div>
                  </div>
                  {showError && (
                     <p className="error-msg" style={{ textAlign: "center" }}>
                        {errorMessages}
                     </p>
                  )}

                  <NavigateButtons currentStep={currentStep} setCurrentStep={setCurrentStep} />
               </form>
            </div>
         </div>
      </section>
   );
}
